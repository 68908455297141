import Layout from "@ui/layout";
import HomeHeader from "@views/Home/components/HomeHeader";
import React from "react";

const TermsAndConditions = () => {
  return (
    <Layout
      // header={{ children: <HomeHeader /> }}
      seo={{
        title: "Terms and Conditions - Reactemplates.com",
        description:
          "Terms and conditions for using Reactemplates.com. Learn about licensing, usage rights, and policies for our React templates and themes.",
      }}
    >
      <div className="container mx-auto px-4 py-8 max-w-4xl mt-10">
        <h1 className="text-3xl font-bold mb-6">
          Terms and Conditions
        </h1>
        <p className="italic text-gray-600 mb-8">
          <strong>Last Updated:</strong> March 1, 2025
        </p>

        <p className="mb-4">
          Welcome to Reactemplates.com. Please read these Terms and
          Conditions ("Terms") carefully before using our website and
          services.
        </p>

        <div className="mb-8">
          <h2 className="text-2xl font-bold mt-8 mb-4">
            1. Acceptance of Terms
          </h2>
          <p>
            By accessing or using Reactemplates.com ("the Website"),
            you agree to be bound by these Terms and Conditions, our
            Privacy Policy, and any other policies referenced herein.
            If you do not agree with any part of these terms, please
            do not use our Website.
          </p>
        </div>

        <div className="mb-8">
          <h2 className="text-2xl font-bold mt-8 mb-4">
            2. Definitions
          </h2>
          <ul className="list-disc pl-6 mb-4">
            <li className="mb-2">
              "Website" refers to Reactemplates.com
            </li>
            <li className="mb-2">
              "User," "You," and "Your" refer to individuals or
              entities accessing or using the Website
            </li>
            <li className="mb-2">
              "We," "Us," and "Our" refer to the owners and operators
              of Reactemplates.com
            </li>
            <li className="mb-2">
              "Content" refers to all materials available on the
              Website, including but not limited to React templates,
              themes, code, documentation, and blog posts
            </li>
            <li className="mb-2">
              "Subscription" refers to any paid plan that grants
              access to premium Content
            </li>
          </ul>
        </div>

        <div className="mb-8">
          <h2 className="text-2xl font-bold mt-8 mb-4">
            3. Account Registration
          </h2>
          <p className="mb-2">
            3.1. To access certain features of the Website, you may
            need to create an account.
          </p>
          <p className="mb-2">
            3.2. You are responsible for maintaining the
            confidentiality of your account information and for all
            activities that occur under your account.
          </p>
          <p className="mb-2">
            3.3. You must provide accurate, current, and complete
            information during registration.
          </p>
          <p className="mb-2">
            3.4. We reserve the right to terminate or suspend accounts
            at our discretion.
          </p>
        </div>

        <div className="mb-8">
          <h2 className="text-2xl font-bold mt-8 mb-4">
            4. License and Usage Rights
          </h2>
          <p className="mb-2">
            4.1. <span className="font-bold">Free Templates:</span>{" "}
            Free templates available on our Website may be used for
            personal and commercial projects, subject to the
            limitations specified in section 4.3.
          </p>
          <p className="mb-2">
            4.2. <span className="font-bold">Premium Templates:</span>{" "}
            Premium templates require the purchase of a license and
            are subject to the specific license terms provided at the
            time of purchase.
          </p>
          <p className="mb-2">
            4.3. <span className="font-bold">Attribution:</span>{" "}
            Attribution is not required for premium templates. For
            free templates, attribution is appreciated but not
            required unless specified in the individual template
            documentation.
          </p>
        </div>

        <div className="mb-8">
          <h2 className="text-2xl font-bold mt-8 mb-4">
            5. Payments and Subscriptions
          </h2>
          <p className="mb-2">
            5.1. Prices for our products and subscriptions are subject
            to change without notice.
          </p>
          <p className="mb-2">
            5.2. All purchases are final and non-refundable, except as
            required by applicable law.
          </p>
          <p className="mb-2">
            5.3. Subscriptions automatically renew until canceled. You
            can cancel your subscription at any time through your
            account settings.
          </p>
          <p className="mb-2">
            5.4. If you cancel a subscription, you may continue to
            have access until the end of your current billing period.
          </p>
        </div>

        <div className="mb-8">
          <h2 className="text-2xl font-bold mt-8 mb-4">
            6. Intellectual Property Rights
          </h2>
          <p className="mb-2">
            6.1. All Content on the Website, including but not limited
            to templates, themes, code, designs, and documentation, is
            the property of Reactemplates.com or its content suppliers
            and is protected by intellectual property laws.
          </p>
          <p className="mb-2">
            6.2. Our templates and themes are licensed, not sold. You
            receive a license to use them according to these Terms,
            but we retain all intellectual property rights.
          </p>
          <p className="mb-2">
            6.3. You retain all rights to your own content that you
            incorporate into templates.
          </p>
        </div>

        <div className="mb-8">
          <h2 className="text-2xl font-bold mt-8 mb-4">
            7. User Content and Conduct
          </h2>
          <p className="mb-2">
            7.1. You may have the opportunity to submit content to our
            Website, such as comments, reviews, or forum posts.
          </p>
          <p className="mb-2">
            7.2. By submitting content, you grant us a non-exclusive,
            royalty-free, worldwide license to use, modify, adapt,
            publish, translate, and distribute your content.
          </p>
          <p className="mb-2">
            7.3. You agree not to submit content that:
          </p>
          <ul className="list-disc pl-6 mb-4">
            <li className="mb-2">
              Is illegal, harmful, threatening, defamatory, or
              otherwise objectionable
            </li>
            <li className="mb-2">
              Infringes on the intellectual property rights of others
            </li>
            <li className="mb-2">
              Contains malware, viruses, or other malicious code
            </li>
            <li className="mb-2">
              Constitutes unauthorized commercial solicitation
            </li>
            <li className="mb-2">
              Impersonates another person or entity
            </li>
          </ul>
        </div>

        <div className="mb-8">
          <h2 className="text-2xl font-bold mt-8 mb-4">
            8. Disclaimer of Warranties
          </h2>
          <p className="mb-2 uppercase font-bold">
            8.1. THE WEBSITE AND ITS CONTENT ARE PROVIDED "AS IS"
            WITHOUT WARRANTY OF ANY KIND, EITHER EXPRESS OR IMPLIED,
            INCLUDING BUT NOT LIMITED TO WARRANTIES OF
            MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, OR
            NON-INFRINGEMENT.
          </p>
          <p className="mb-2">
            8.2. We do not guarantee that the Website will be
            error-free, secure, or uninterrupted, or that any defects
            will be corrected.
          </p>
          <p className="mb-2">
            8.3. Templates and themes are provided without warranty
            regarding their performance, suitability, or compatibility
            with specific frameworks or environments.
          </p>
        </div>

        <div className="mb-8">
          <h2 className="text-2xl font-bold mt-8 mb-4">
            9. Limitation of Liability
          </h2>
          <p className="mb-2 uppercase font-bold">
            9.1. TO THE MAXIMUM EXTENT PERMITTED BY LAW, WE SHALL NOT
            BE LIABLE FOR ANY INDIRECT, INCIDENTAL, SPECIAL,
            CONSEQUENTIAL, OR PUNITIVE DAMAGES ARISING FROM YOUR USE
            OF THE WEBSITE OR ITS CONTENT.
          </p>
          <p className="mb-2">
            9.2. Our total liability for any claims arising under
            these Terms shall not exceed the amount you paid to us in
            the twelve (12) months preceding the claim.
          </p>
        </div>

        <div className="mb-8">
          <h2 className="text-2xl font-bold mt-8 mb-4">
            10. Indemnification
          </h2>
          <p>
            You agree to indemnify, defend, and hold harmless
            Reactemplates.com, its officers, directors, employees, and
            agents from and against any claims, liabilities, damages,
            losses, costs, or expenses arising out of or in connection
            with your use of the Website, violation of these Terms, or
            infringement of any rights of another party.
          </p>
        </div>

        <div className="mb-8">
          <h2 className="text-2xl font-bold mt-8 mb-4">
            11. Third-Party Links and Services
          </h2>
          <p className="mb-2">
            11.1. The Website may contain links to third-party
            websites or services. We are not responsible for the
            content or practices of any linked third-party sites.
          </p>
          <p className="mb-2">
            11.2. Your use of third-party websites or services is
            subject to the terms and policies of those third parties.
          </p>
        </div>

        <div className="mb-8">
          <h2 className="text-2xl font-bold mt-8 mb-4">
            12. Modification of Terms
          </h2>
          <p className="mb-2">
            12.1. We reserve the right to modify these Terms at any
            time. Changes will be effective immediately upon posting
            to the Website.
          </p>
          <p className="mb-2">
            12.2. Your continued use of the Website after any changes
            constitutes acceptance of the modified Terms.
          </p>
          <p className="mb-2">
            12.3. We will make reasonable efforts to notify you of
            significant changes.
          </p>
        </div>

        <div className="mb-8">
          <h2 className="text-2xl font-bold mt-8 mb-4">
            13. Termination
          </h2>
          <p className="mb-2">
            13.1. We may terminate or suspend your access to the
            Website at any time, with or without cause and without
            prior notice.
          </p>
          <p className="mb-2">
            13.2. Upon termination, your right to use the Website will
            immediately cease.
          </p>
          <p className="mb-2">
            13.3. All provisions of these Terms that by their nature
            should survive termination shall survive, including
            ownership provisions, warranty disclaimers, indemnity, and
            limitations of liability.
          </p>
        </div>

        <div className="mb-8">
          <h2 className="text-2xl font-bold mt-8 mb-4">
            14. Export Control
          </h2>
          <p>
            You agree to comply with all applicable export and
            re-export control laws and regulations, including the
            Export Administration Regulations maintained by the U.S.
            Department of Commerce.
          </p>
        </div>

        <div className="mb-8">
          <h2 className="text-2xl font-bold mt-8 mb-4">
            15. Governing Law and Dispute Resolution
          </h2>
          <p className="mb-2">
            15.1. These Terms shall be governed by and construed in
            accordance with the laws of [Your Jurisdiction], without
            regard to its conflict of law principles.
          </p>
          <p className="mb-2">
            15.2. Any dispute arising out of or relating to these
            Terms shall be subject to the exclusive jurisdiction of
            the courts located in [Your Jurisdiction].
          </p>
          <p className="mb-2">
            15.3. Any dispute arising from these Terms shall first be
            attempted to be resolved through informal negotiations. If
            negotiations fail, the dispute shall be submitted to
            binding arbitration in accordance with the rules of
            [Arbitration Association].
          </p>
        </div>

        <div className="mb-8">
          <h2 className="text-2xl font-bold mt-8 mb-4">
            16. Severability
          </h2>
          <p>
            If any provision of these Terms is found to be
            unenforceable or invalid, that provision shall be limited
            or eliminated to the minimum extent necessary so that the
            Terms shall otherwise remain in full force and effect.
          </p>
        </div>

        <div className="mb-8">
          <h2 className="text-2xl font-bold mt-8 mb-4">
            17. Entire Agreement
          </h2>
          <p>
            These Terms constitute the entire agreement between you
            and Reactemplates.com regarding the use of the Website and
            supersede all prior agreements and understandings.
          </p>
        </div>

        <div className="mb-8 p-4 bg-gray-100 rounded-lg">
          <h2 className="text-2xl font-bold mb-4">
            18. Contact Information
          </h2>
          <p className="mb-2">
            If you have any questions about these Terms, please
            contact us at:
          </p>
          <p>
            <strong>Email:</strong> reactemplates@gmail.com
            <br />
          </p>
        </div>

        <hr className="my-8" />

        <p>
          By using Reactemplates.com, you acknowledge that you have
          read, understood, and agree to be bound by these Terms and
          Conditions.
        </p>
      </div>
    </Layout>
  );
};

export default TermsAndConditions;
